import {
  Divider,
  List,
  Drawer as MuiDrawer,
  SwipeableDrawer as MuiSwipeableDrawer,
  Stack,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { IterableElement } from "type-fest";
import { FeatureFlag, LayoutRouteQuery } from "../../../../generated/urql";
import { useRoutesResolver } from "../../../../lib/hooks/useRoutesResolver";
import { RouteClasses } from "../../../../routes/RootRoute/RootRoute";
import { Box } from "../../../core/v2/Box/Box";
import { Dialog } from "../../../core/v2/Dialog/Dialog";
import {
  AssetsIcon,
  BaseIconPublicProps,
  CashflowIcon,
  ConfigureIcon,
  DocumentsIcon,
  OpportunitiesIcon,
  PortfolioIcon,
  TransactionsIcon,
} from "../../../core/v2/Icon/Icon";
import { DeveloperConsole } from "../DeveloperConsole/DeveloperConsole";
import { EarlyAdopterConsole } from "../EarlyAdopterConsole/EarlyAdopterConsole";
import { SidebarDrawerItem } from "./SidebarDrawerItem";

// In the parent width we are considering the border size
// both left and right of 2px. So the actual width of the
// drawer is the value in the variable minus 4px.
const drawerClosedWidth = "52px";
const drawerOpenWidth = "224px";

/**
 * The sidebar drawer component.
 */
export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => {
  const transition = theme.transitions.create(["width", "opacity"], {
    easing: theme.transitions.easing.sharp,
    duration: "0.2s",
  });

  const openMediaQuery = theme.breakpoints.up("lg");

  return {
    width: `calc(${drawerClosedWidth} + ${theme.spacing(3)})`,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    transition,

    [openMediaQuery]: {
      width: `calc(${drawerOpenWidth} + ${theme.spacing(3)})`,
    },

    "& .MuiDrawer-paper": {
      width: `calc(${drawerClosedWidth} + ${theme.spacing(3)})`,
      boxSizing: "border-box",
      background: "transparent",
      border: 0,
      transition,

      [openMediaQuery]: {
        width: `calc(${drawerOpenWidth} + ${theme.spacing(3)})`,
      },
    },

    "& .MuiListItem-root": {
      width: drawerClosedWidth,
      transition,

      [openMediaQuery]: {
        width: drawerOpenWidth,
      },
    },

    "& .MuiList-root.secondary": {
      opacity: 0,
      transition,
      pointerEvents: "none",

      [openMediaQuery]: {
        opacity: 1,
        pointerEvents: "auto",
      },

      "& *": {
        pointerEvents: "none",

        [openMediaQuery]: {
          pointerEvents: "auto",
        },
      },
    },

    "& .MuiListItemText-root": {
      opacity: 0,
      transition,

      [openMediaQuery]: {
        opacity: 1,
      },
    },
  };
});

/**
 * The sidebar drawer component.
 * This component is used when the screen matches
 * a mobile device.
 */
export const SwipeableDrawer = styled(MuiSwipeableDrawer)(({ theme }) => {
  return {
    width: `calc(${drawerOpenWidth} + ${theme.spacing(3)})`,

    "& .MuiDrawer-paper": {
      width: `calc(${drawerOpenWidth} + ${theme.spacing(3)})`,
    },
  };
});

export type DrawerMenuItem = {
  slug: string;
  name: string;
  Icon?: React.FC<BaseIconPublicProps>;
  route?: string;
  selected?: boolean;
  onClick?: () => void;
};

type Props = {
  authUser: Pick<
    LayoutRouteQuery["authUser"],
    "firstName" | "lastName" | "featureFlags"
  >;
  userInvestors: LayoutRouteQuery["authUser"]["investors"];
  selectedUserInvestor?: IterableElement<
    LayoutRouteQuery["authUser"]["investors"]
  >;
  currentRoute?: string;
  drawerOpen?: boolean;
  onDrawerOpen?: () => void;
  onDrawerClose?: () => void;
};

/**
 * The sidebar menu and drawer when the screen matches
 * a mobile device.
 */
export const SidebarDrawer: React.FC<Props> = ({
  authUser,
  currentRoute,
  selectedUserInvestor,
  drawerOpen,
  onDrawerOpen,
  onDrawerClose,
}) => {
  const { generatePathForRoute } = useRoutesResolver<RouteClasses>();
  const [showDeveloperConsole, setShowDeveloperConsole] = useState(false);
  const [showEarlyAdopterConsole, setShowEarlyAdopterConsole] = useState(false);
  const [showFMDisclaimerDialog, setShowFMDisclaimerDialog] = useState(false);
  const hasDeveloperModeFeatureFlag = useMemo(
    () => authUser.featureFlags.includes(FeatureFlag.EnableDeveloperMode),
    [authUser.featureFlags],
  );
  const hasEarlyAdopterFeatureFlag = useMemo(
    () => authUser.featureFlags.includes(FeatureFlag.EnableEarlyAdopterMode),
    [authUser.featureFlags],
  );
  const theme = useTheme();
  const showLeftDrawer = useMediaQuery(theme.breakpoints.up("md"));

  const MenuItems = useMemo(
    () => () => (
      <>
        <SidebarDrawerItem
          slug="portfolio"
          Icon={PortfolioIcon}
          name={"Portfolio"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("PortfolioRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={currentRoute === "PortfolioRoute"}
          onClick={onDrawerClose}
        />
        <SidebarDrawerItem
          slug="assets"
          Icon={AssetsIcon}
          name={"Assets"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("InvestmentsRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={currentRoute === "InvestmentsRoute"}
          onClick={onDrawerClose}
        />
        <SidebarDrawerItem
          slug="new-opportunities"
          Icon={OpportunitiesIcon}
          name={"Invest"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("AssetsRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={
            currentRoute === "AssetsRoute" || currentRoute === "AssetRoute"
          }
          onClick={onDrawerClose}
        />
        <SidebarDrawerItem
          slug="cashflow-simulator"
          Icon={CashflowIcon}
          name={"Cash Flow"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("CashflowSimulatorRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={currentRoute === "CashflowSimulatorRoute"}
          onClick={onDrawerClose}
        />
        <SidebarDrawerItem
          slug="data-room"
          Icon={DocumentsIcon}
          name={"Data Room"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("DocumentsRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={currentRoute === "DocumentsRoute"}
          onClick={onDrawerClose}
        />
        <SidebarDrawerItem
          slug="transactions"
          Icon={TransactionsIcon}
          name={"Transactions"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("TransactionsRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={currentRoute === "TransactionsRoute"}
          onClick={onDrawerClose}
        />

        <Divider sx={{ mt: 1 }} />

        <Box mt={2} maxWidth="100%">
          <button
            style={{
              border: "0px",
              background: "transparent",
              cursor: "pointer",
              padding: "0px",
            }}
            onClick={() => setShowFMDisclaimerDialog(true)}
          >
            <Typography
              variant="body2"
              whiteSpace="normal"
              textAlign="left"
              sx={{ opacity: 0.5 }}
            >
              Heritage AIF is managed and marketed by{" "}
              <span style={{ textDecoration: "underline" }}>
                IQEQ Fund Management (Luxembourg) S.A.
              </span>
            </Typography>
          </button>
        </Box>

        {hasDeveloperModeFeatureFlag && (
          <>
            <Divider sx={{ mt: 1 }} />

            <SidebarDrawerItem
              slug="developer"
              Icon={ConfigureIcon}
              name={"Feature Flags"}
              onClick={() => setShowDeveloperConsole(true)}
            />

            <Box pl={1}>
              <DeveloperConsole mode="preview" user={authUser} />
            </Box>
          </>
        )}
        {hasEarlyAdopterFeatureFlag && (
          <SidebarDrawerItem
            slug="early-adopter"
            Icon={ConfigureIcon}
            name={"Early Adopter"}
            onClick={() => setShowEarlyAdopterConsole(true)}
          />
        )}
      </>
    ),
    [
      currentRoute,
      selectedUserInvestor,
      onDrawerClose,
      generatePathForRoute,
      hasDeveloperModeFeatureFlag,
      hasEarlyAdopterFeatureFlag,
      authUser,
    ],
  );

  return (
    <>
      {showLeftDrawer ? (
        <Drawer variant="permanent">
          <Toolbar />

          <Box
            sx={{
              overflow: "hidden",
              mt: 3,
              ml: 3,
              height: "100%",
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              height="100%"
            >
              <List
                sx={{
                  // This will fix an inssue in which the borders
                  // of the items are cutted off. This should be
                  // the same size of the said borders.
                  py: "2px",
                }}
              >
                <MenuItems />
              </List>
            </Stack>
          </Box>
        </Drawer>
      ) : undefined}

      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onOpen={onDrawerOpen ?? (() => undefined)}
        onClose={onDrawerClose ?? (() => undefined)}
      >
        <Toolbar />

        <Stack
          direction="column"
          justifyContent="space-between"
          height="100%"
          sx={{
            p: 1,
          }}
        >
          <List>
            <MenuItems />
          </List>
        </Stack>
      </SwipeableDrawer>

      <Dialog
        title="Developer console"
        open={showDeveloperConsole}
        onClose={() => setShowDeveloperConsole(false)}
        minWidth={300}
      >
        <DeveloperConsole user={authUser} mode="edit" />
      </Dialog>

      <Dialog
        title="Early Adopter console"
        open={showEarlyAdopterConsole}
        onClose={() => setShowEarlyAdopterConsole(false)}
        minWidth={300}
      >
        <EarlyAdopterConsole />
      </Dialog>

      <Dialog
        title="IQEQ Fund Management"
        open={showFMDisclaimerDialog}
        onClose={() => setShowFMDisclaimerDialog(false)}
        maxWidth={700}
      >
        <Typography whiteSpace="normal">
          IQEQ Fund Management (Luxembourg) S.A. is a Luxembourg alternative
          investment fund manager (AIFM) authorised and supervised by CSSF.
          Access to and use of this platform are granted by the AIFM.
        </Typography>
      </Dialog>
    </>
  );
};
