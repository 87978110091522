import React, { useMemo } from "react";
import { useRecoilState } from "recoil";
import { User } from "../../../../generated/urql";
import {
  enableCapitalCallDocuments,
  enablePortfolioAudit,
  enableDistributionStatusBadge,
} from "../../../../state/developer";
import { Box } from "../../../core/v2/Box/Box";
import { Checkbox } from "../../../core/v2/Checkbox/Checkbox";
import { Chip } from "../../../core/v2/Chip/Chip";

type FrontendFlag = {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
};

type Props = {
  user: Pick<User, "featureFlags">;
  mode: "preview" | "edit";
};

export const DeveloperConsole: React.FC<Props> = ({ mode }) => {
  const [isPortfolioV2AuditEnabled, setPortfolioV2AuditEnabled] =
    useRecoilState(enablePortfolioAudit);

  const [isCapitalCallDocumentsEnabled, setCapitalCallDocumentsEnabled] =
    useRecoilState(enableCapitalCallDocuments);

  const [isDistributionStatusBadgeEnabled, setDistributionStatusBadgeEnabled] =
    useRecoilState(enableDistributionStatusBadge);

  const flags = useMemo<Array<FrontendFlag>>(
    () => [
      {
        value: isPortfolioV2AuditEnabled,
        onChange: setPortfolioV2AuditEnabled,
        label: "Portfolio Audit",
      },
      {
        value: isCapitalCallDocumentsEnabled,
        onChange: setCapitalCallDocumentsEnabled,
        label: "Capital Call Documents",
      },
      {
        value: isDistributionStatusBadgeEnabled,
        onChange: setDistributionStatusBadgeEnabled,
        label: "Distribution Status Badge",
      },
    ],
    [
      isPortfolioV2AuditEnabled,
      isCapitalCallDocumentsEnabled,
      isDistributionStatusBadgeEnabled,
      setCapitalCallDocumentsEnabled,
      setDistributionStatusBadgeEnabled,
      setPortfolioV2AuditEnabled,
    ],
  );

  return (
    <Box>
      {flags.map((flag) => (
        <Box mt={1} key={flag.label}>
          {mode === "edit" ? (
            <Checkbox
              checked={flag.value}
              label={flag.label}
              onChange={() => flag.onChange(!flag.value)}
            />
          ) : (
            <Chip
              size="small"
              backgroundColor={flag.value ? "accentLight" : "neutral500"}
              textColor="invariantWhite"
            >
              {flag.label}
            </Chip>
          )}
        </Box>
      ))}
    </Box>
  );
};
