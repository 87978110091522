import { Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  array as A,
  date as D,
  function as F,
  option as O,
  ord as OR,
  record as R,
  readonlyArray as RA,
  string as S,
} from "fp-ts";
import React, { useMemo } from "react";
import { useRecoilState } from "recoil";
import { IterableElement } from "type-fest";
import { useQuery } from "urql";
import {
  PortfolioRouteDocument,
  PortfolioRouteQuery,
  PortfolioRouteQueryVariables,
} from "../../../../generated/urql";
import { useInvestor } from "../../../../hooks/useInvestor";
import { useRetrieveCutoffs } from "../../../../hooks/useRetrieveCutoffs";
import { getQuartersFromCutoffs } from "../../../../lib/utils/portfolio";
import { portfolioSelectedQuarter } from "../../../../state/portfolioSelectedQuarter";
import {
  formatCutoffDate,
  getTodayCutoff,
  isSameCutoffDate,
} from "../../../../utils/cutoff";
import { disableSuspenseContext } from "../../../../utils/queries";
import { DraftQuarterChip } from "../../../core/v2/Chip/Chip";
import { Select, SelectOption } from "../../../core/v2/Select/Select";

type PortfolioSituation = IterableElement<
  PortfolioRouteQuery["investor"]["portfolioSituation"]
>;

type Props = {
  value: string;
  onChange: (cutoffDate: string) => void;
};

/**
 * A select component that allows to select a specific quarter
 * derived from the `portfolioSituation` array.
 */
export const QuarterSelect: React.FC<Props> = ({ value, onChange }) => {
  const { confirmedCutoffs, draftCutoffs, isDraftCutoff } =
    useRetrieveCutoffs();
  const theme = useTheme();
  const mqMinMd = useMediaQuery(theme.breakpoints.up("md"));
  const isSmallDevice = !mqMinMd;

  const cutoffs = useMemo(
    () => [...confirmedCutoffs, ...draftCutoffs],
    [confirmedCutoffs, draftCutoffs],
  );

  // Retrieve all the available quarters from the `portfolioSituation` array
  // in order to be displayed in the dropdown menu.
  //
  // FIXME: This is a quite complex and inefficient computation that could
  // be probably improved further in the future having a better data structure.
  const availableQuartersItems = useMemo<Array<SelectOption>>(() => {
    return F.pipe(
      cutoffs,
      A.sort(OR.reverse(D.Ord)),
      getQuartersFromCutoffs,
      R.toEntries,
      (_) => _.sort(([a], [b]) => S.Ord.compare(b, a)),
      A.reduce<[string, Record<string, string>], Array<SelectOption>>(
        [],
        (acc, [quarter, quarters]) =>
          F.pipe(
            acc,
            A.append<SelectOption>({
              kind: "section",
              key: quarter,
              label: quarter,
            }),
            A.concat(
              F.pipe(
                quarters,
                R.toEntries,
                (_) => _.sort(([a], [b]) => S.Ord.compare(b, a)),
                A.map<[string, string], SelectOption>(([quarter, label]) => ({
                  kind: "value",
                  key: quarter,
                  value: quarter,
                  label: isDraftCutoff(new Date(quarter)) ? (
                    <Stack
                      direction="row"
                      gap={0}
                      width="100%"
                      justifyContent="space-between"
                    >
                      {label}

                      <DraftQuarterChip>Draft</DraftQuarterChip>
                    </Stack>
                  ) : (
                    label
                  ),
                })),
              ),
            ),
          ),
      ),
    );
  }, [cutoffs, isDraftCutoff]);

  return (
    <Select
      options={availableQuartersItems}
      value={value}
      placeholder="Select a quarter"
      onChange={onChange}
      minWidth={!isSmallDevice ? 280 : undefined}
    />
  );
};

/**
 * A hook that allows to manage the selected quarter for the
 * `QuarterSelect` component.
 */
export function useQuarterSelectManager(): {
  selectedQuarter: string;
  setSelectedQuarter: (value: string) => void;
  maybePortfolioSituation: O.Option<PortfolioSituation>;
  loading: boolean;
} {
  const investorId = useInvestor()?.id;
  const defaultCutoff = useMemo(() => formatCutoffDate(getTodayCutoff()), []);

  // Set the current quarter to the most recent one,
  // which is the last one in the `portfolioSituation` array.
  const [selectedQuarter, setSelectedQuarter] = useRecoilState(
    portfolioSelectedQuarter,
  );

  const safeSelectedQuarter = useMemo(
    () => selectedQuarter ?? defaultCutoff,
    [selectedQuarter, defaultCutoff],
  );

  const [{ data, fetching }] = useQuery<
    PortfolioRouteQuery,
    PortfolioRouteQueryVariables
  >({
    query: PortfolioRouteDocument,
    variables: {
      investorId: investorId ?? "",
      cutoffs: [safeSelectedQuarter],
      flags: [],
    },
    pause: !investorId,
    context: disableSuspenseContext,
  });

  const maybePortfolioSituation = useMemo(() => {
    return F.pipe(
      data?.investor.portfolioSituation ?? [],
      RA.findFirst(({ cutoffDate }) => {
        return isSameCutoffDate(cutoffDate, safeSelectedQuarter);
      }),
    );
  }, [data, safeSelectedQuarter]);

  const loading = useMemo(() => fetching, [fetching]);

  const stableReturnValue = useMemo(
    () => ({
      selectedQuarter: selectedQuarter ?? defaultCutoff,
      setSelectedQuarter,
      maybePortfolioSituation,
      loading,
    }),
    [
      selectedQuarter,
      defaultCutoff,
      setSelectedQuarter,
      maybePortfolioSituation,
      loading,
    ],
  );

  return stableReturnValue;
}
