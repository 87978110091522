import {
  DataGridPremium,
  GridColDef,
  GridGroupingColDefOverride,
  GridRenderCellParams,
  useGridApiContext,
  GRID_AGGREGATION_FUNCTIONS,
  GridColumnVisibilityModel,
  GridValueFormatter,
  GridSlotsComponent,
  GridTreeNodeWithRender,
  GridTreeNode,
  GridAggregationPosition,
} from "@mui/x-data-grid-premium";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import { TablePagination } from "./TablePagination";
import { Stack, Typography, useTheme } from "@mui/material";
import {
  ArrowDown,
  ArrowUp,
  ChevronDown,
  ChevronRight,
  DownloadIcon,
  ViewIcon,
  Warning,
} from "../Icon/Icon";
import { CellExternalLink } from "./CellExternalLink";
import { match } from "ts-pattern";
import { CellInternalLink } from "./CellInternalLink";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import isEmpty from "lodash/isEmpty";
import {
  moneySorter,
  moneySumAggregation,
  moneySumAggregationLabel,
} from "./moneyTableUtilities";
import {
  simpleMoneySorter,
  simpleMoneySumAggregation,
  simpleMoneySumAggregationLabel,
} from "./simpleMoneyTableUtilities";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { Box } from "../Box/Box";
import { AuditTrailCell } from "../../../investments/AuditTrailCell/AuditTrailCell";
import { TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";
import { Money } from "@heritageholdings/lib-commons-finance/lib/units/money";
import { parseMoneyCurrency } from "../../../../utils/data/SimpleMoney";
import {
  formatDateDisplay1,
  formatDateTimeDisplay1,
} from "../../../../utils/date";
import { ErrorBoundary } from "../../../../lib/utils/datadog/ErrorBoundary";
import { GenericErrorBox } from "../../../commons/GenericErrorBox";
import { IconButton } from "../IconButton/IconButton";
import { ValueOf } from "type-fest";
import {
  customFirstValAggregation,
  customFirstValAggregationLabel,
  customSameValAggregation,
  customSameValAggregationLabel,
} from "./customTableUtilities";
import { BaseChipProps, Chip } from "../Chip/Chip";

// Default DataGrid column settings.
const defaultColumnSettings: Omit<GridColDef, "headerName" | "field"> = {
  disableColumnMenu: true,
  flex: 1,
  minWidth: 100,
};

// Predefined options for the pagination.
const pagesOptions = [25, 50, 100];

/**
 * Fallback value for a table cell.
 */
export const tableFallbackValue = "-";

/**
 * Base type for a row of the `Table` component.
 */
export type BaseTableRow = {
  id: string;
  path?: Array<string>;
};

/**
 * Type representing an active filter on a column of the `Table` component.
 */
export type ActiveTableColumnFilter<R extends BaseTableRow> = {
  column: keyof R;
  value: string | number | boolean;
};

/**
 * Type representing the parameters passed to the `generateLink` function.
 */
type GenerateParams<R extends BaseTableRow, T> = {
  row: R | undefined;
  value: T;
};

/**
 * Function used to format a leaf in a group.
 */
type FormatLeafFn = (value: string | undefined) => string | undefined;

/**
 * A default sorting model for the `Table` component.
 */
export type DefaultSortingModel<R extends BaseTableRow> = {
  field: keyof R;
  sort: "asc" | "desc";
};

/**
 * Type representing a document link of the `Table` component.
 */
export type DocumentLinkStructure = (
  | { kind: "download"; link: string }
  | { kind: "view"; link: string }
) & { onClick?: () => void };

/**
 * Type representing a custom column kind of the `Table` component.
 */
type CustomColumnKind<R extends BaseTableRow, T> =
  | {
      kind: "externalLink";
      generateLink: (params: GenerateParams<R, T>) => string | undefined;
      onLinkClick?: (params: GenerateParams<R, T>) => (() => void) | undefined;
    }
  | {
      kind: "internalLink";
      generateLink: (params: GenerateParams<R, T>) => string;
    }
  | {
      kind: "customColor";
      generateColor: (params: GenerateParams<R, T>) => string;
    }
  | {
      kind: "withChip";
      generateChipColor: (
        params: GenerateParams<R, T>,
      ) => Pick<BaseChipProps, "backgroundColor" | "textColor">;
    }
  | {
      kind: "portfolioAudit";
      generateJsonAudit: (params: GenerateParams<R, T>) => string;
    }
  | {
      kind: "valueWithWarning";
      generateMessage: (params: GenerateParams<R, T>) => string | undefined;
    }
  | {
      kind: "documentLink";
      generateLink: (
        params: GenerateParams<R, T>,
      ) => DocumentLinkStructure | undefined;
      hideOnNodeGroup?: GridTreeNode["type"];
    }
  | {
      kind: "withInlineComponent";
      generateCell: (params: GenerateParams<R, T>) => {
        prefixComponent?: React.ReactNode;
        postfixComponent?: React.ReactNode;
        value?: string;
        boldText?: boolean;
      };
    }
  | {
      kind: "withTooltip";
      generateTooltip: (params: GenerateParams<R, T>) => React.ReactNode;
    };

/**
 * Type representing a grouping column of the `Table` component.
 */
export type TableGroupingColumn<R extends BaseTableRow> =
  GridGroupingColDefOverride<R> & {
    formatLeafValue?: FormatLeafFn;
  };

/**
 * List of possible types for a column of the `Table` component.
 */
export type TableColumnType =
  | "number"
  | "date"
  | "dateTime"
  | "Money"
  | "SimpleMoney"
  | "custom";

// Utility type to get the values of a row.
type UnionRowValues<R extends BaseTableRow> = ValueOf<Omit<R, "id" | "path">>;

// Utility type for the `GridValueFormatter`.
export type CustomGridValueFormatter<R extends BaseTableRow> =
  GridValueFormatter<R, UnionRowValues<R>, unknown, UnionRowValues<R>>;

/**
 * Type representing a column of the `Table` component.
 */
export type TableColumn<R extends BaseTableRow, K extends keyof R = keyof R> = {
  headerName: string;
  field: K;
  flex?: number;
  minWidth?: number;
  width?: number;
  customColumnKind?: CustomColumnKind<R, unknown>;
  sortable?: boolean;
  path?: Array<string>;
  type?: TableColumnType;
  pinnedRowRenderCell?: GridColDef<R>["renderCell"];

  align?: "left" | "center" | "right";
  headerAlign?: "left" | "center" | "right";

  // FIXME: When "Type Argument Placeholders" will land we could replace this
  // `unknown` with a more accurate type. Ideally we'd need to
  // have a narrow `R[K]` (if the field value of `R` is a `string` we want
  // that type only and not a union of all possible types).
  // For reference:
  // - https://github.com/microsoft/TypeScript/pull/26349
  // - https://www.totaltypescript.com/type-argument-placeholders-typescript-5-2-most-discussed-feature
  valueFormatter?: CustomGridValueFormatter<R>;

  // Pass-through to the `GridColDef` component, overrides customColumnKind.
  renderCell?: GridColDef<R>["renderCell"];
};

/**
 * Type representing the aggregation model of the `Table` component.
 */
export type TableAggregationModel<R extends BaseTableRow> = {
  [key in keyof R]?:
    | "sum"
    | "avg"
    | "min"
    | "max"
    | typeof moneySumAggregationLabel
    | typeof simpleMoneySumAggregationLabel
    | typeof customFirstValAggregationLabel
    | typeof customSameValAggregationLabel;
};

/**
 * Custom Tree Data parent cell.
 */
const genCustomGridTreeDataGroupingCell =
  (formatLeafValue?: FormatLeafFn, defaultOpen?: boolean) =>
  <R extends BaseTableRow>(props: GridRenderCellParams<R>) => {
    const { id, field, rowNode, formattedValue, row } = props;
    const apiRef = useGridApiContext();
    const [open, setOpen] = useState(
      (defaultOpen ?? false) && rowNode.type === "group",
    );
    const theme = useTheme();
    const palette = useHeritageV2Palette();

    const handleClick = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        if (rowNode.type !== "group") return;

        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);

        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
        setOpen((open) => !open);
      },
      [apiRef, id, field, rowNode],
    );

    const leafValue = useMemo(() => {
      if (!row.path) return null;

      const leaf = row.path[row.path.length - 1];

      return formatLeafValue ? formatLeafValue(leaf) : leaf;
    }, [row]);

    const Chevron = useMemo(() => (open ? ChevronDown : ChevronRight), [open]);

    return (
      <Box onClick={handleClick}>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          sx={{
            cursor: rowNode.type !== "leaf" ? "pointer" : undefined,
          }}
        >
          {rowNode.type === "group" ? (
            <Box flexGrow={0} flexShrink={0} flexBasis="auto">
              <Box pl={0} pr={1} sx={{ lineHeight: 0 }}>
                <Chevron variant="small" stroke={palette.accent} />
              </Box>
            </Box>
          ) : undefined}

          <Box
            ml={rowNode.type === "leaf" ? 4 : undefined}
            flexGrow={1}
            flexShrink={1}
            flexBasis="100%"
            maxWidth="100%"
            // This is an hack to make the ellipsis work.
            display="grid"
          >
            <Typography
              maxWidth="100%"
              display="block"
              variant="tdLabel"
              className="truncate"
              fontWeight={open ? theme.typography.fontWeightBold : undefined}
            >
              {rowNode.type !== "leaf" ? formattedValue : leafValue}
            </Typography>
          </Box>
        </Stack>
      </Box>
    );
  };

/**
 * Format a `Money` value for a table cell.
 */
export const formatTableMoney = (value: Money) => {
  const formattedAmounts = Object.entries(value.amounts()).map(
    ([currency, value]) =>
      U_Show.currencyWebAppGeneric(
        // FIXME: We should avoid this cast, and this is probably going to
        // render duplicate currencies since the `currencyWebAppGeneric` only manage
        // "EUR" and "USD", while the `Money` class has the "GBP" currency too.
        parseMoneyCurrency(currency),
      ).show(value),
  );

  // If we have no currencies we just display a `-`
  // since we don't know the currency in which we should
  // display the 0.
  if (formattedAmounts.length === 0) return tableFallbackValue;

  return formattedAmounts.join("\n");
};

/**
 * Format a `Money` value for a table cell, but only display the first non-zero currency.
 */
export const formatNonNegativeTableMoney = (value: unknown) => {
  if (!(value instanceof Money)) return tableFallbackValue;

  // Do not display negative values.
  const nonNegativeAmounts = Object.entries(value.amounts()).filter(
    ([_, n]) => n >= 1,
  );

  if (!nonNegativeAmounts.length) return tableFallbackValue;

  return formatTableMoney(new Money(Object.fromEntries(nonNegativeAmounts)));
};

/**
 * Type representing the props of the `Table` component.
 */
export type TableProps<R extends BaseTableRow> = {
  rows: Array<R>;
  columns: Array<TableColumn<R>>;
  filters?: Array<ActiveTableColumnFilter<R>>;
  treeData?: boolean;
  groupingColumn?: TableGroupingColumn<R>;
  defaultGroupsExpanded?: boolean;
  aggregationModel?: TableAggregationModel<R>;
  apiRef?: React.MutableRefObject<GridApiPremium>;
  customEmptyText?: string;
  visibilityModel?: Partial<Record<keyof R, boolean>>;
  defaultPagesIndex?: number;
  defaultSortingModel?: DefaultSortingModel<R>;
  pinnedRightColumns?: Array<keyof R>;
  pinnedBottomRows?: Array<R>;
};

/**
 * Display a table with pagination.
 */
export const Table = <R extends BaseTableRow>({
  rows,
  columns,
  filters,
  treeData,
  groupingColumn,
  defaultGroupsExpanded,
  aggregationModel,
  apiRef,
  customEmptyText,
  visibilityModel,
  defaultSortingModel,
  pinnedRightColumns,
  pinnedBottomRows,
  defaultPagesIndex = 0,
}: TableProps<R>) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    pagesOptions[defaultPagesIndex],
  );

  const computedColumns: Array<GridColDef> = useMemo<Array<GridColDef>>(
    () =>
      columns.map(
        ({
          field,
          customColumnKind,
          align,
          headerAlign,
          width,
          flex,
          minWidth,
          renderCell,
          type,
          pinnedRowRenderCell,
          ...column
        }) => ({
          ...defaultColumnSettings,

          type: match<TableColumnType | undefined, GridColDef["type"]>(type)
            .with("number", () => "number")
            .with("date", () => "date")
            .with("dateTime", () => "dateTime")
            .with("Money", () => "custom")
            .with("SimpleMoney", () => "custom")
            .with("custom", () => "custom")
            .with(undefined, () => "string")
            .exhaustive(),

          // Handle dimensions.
          width,
          flex:
            typeof width === "number"
              ? undefined
              : (flex ?? defaultColumnSettings.flex),
          minWidth: minWidth ?? defaultColumnSettings.minWidth,

          // Define the default value formatter for the column.
          valueFormatter: (value) =>
            match<TableColumn<R>["type"], string>(type)
              .with("Money", () => {
                const moneyValue = value as Money | undefined;

                return moneyValue
                  ? formatTableMoney(moneyValue)
                  : tableFallbackValue;
              })
              .with("date", () => {
                const dateValue = value as Date | undefined;

                return dateValue
                  ? formatDateDisplay1(dateValue)
                  : tableFallbackValue;
              })
              .with("dateTime", () => {
                const dateValue = value as Date | undefined;

                return dateValue
                  ? formatDateTimeDisplay1(dateValue)
                  : tableFallbackValue;
              })
              .otherwise(() => (value ? `${value}` : tableFallbackValue)),

          // The user's preferences.
          ...column,

          field: field.toString(),
          align: align ?? "left",
          headerAlign: headerAlign ?? "left",

          // We need to avoid setting `sortComparator` to `undefined`
          // because it will override the default comparator breaking
          // the table sorting behaviour.
          ...match<
            TableColumn<R>["type"],
            Pick<GridColDef, "sortComparator"> | undefined
          >(type)
            .with("Money", () => ({ sortComparator: moneySorter }))
            .with("SimpleMoney", () => ({
              sortComparator: simpleMoneySorter,
            }))
            .otherwise(() => undefined),

          renderCell:
            renderCell !== undefined
              ? renderCell
              : (
                  renderCellParams: GridRenderCellParams<
                    R,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    any,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    any,
                    GridTreeNodeWithRender
                  >,
                ) => {
                  const { formattedValue, row, value, api } = renderCellParams;

                  const paramObj: GenerateParams<R, unknown> = {
                    row: isEmpty(row) ? undefined : row,
                    value,
                  };

                  const rowNode = api.getRowNode(row.id);

                  if (rowNode?.type === "pinnedRow" && pinnedRowRenderCell)
                    return pinnedRowRenderCell(renderCellParams);

                  return match(customColumnKind)
                    .with(
                      { kind: "externalLink" },
                      ({ generateLink, onLinkClick }) => (
                        <CellExternalLink
                          href={generateLink(paramObj)}
                          onClick={onLinkClick?.(paramObj)}
                        >
                          {formattedValue}
                        </CellExternalLink>
                      ),
                    )
                    .with({ kind: "internalLink" }, ({ generateLink }) => (
                      <CellInternalLink href={generateLink(paramObj)}>
                        {formattedValue}
                      </CellInternalLink>
                    ))
                    .with({ kind: "customColor" }, ({ generateColor }) => (
                      <Typography
                        variant="tdLabel"
                        color={generateColor(paramObj)}
                        className="truncate"
                      >
                        {formattedValue}
                      </Typography>
                    ))
                    .with({ kind: "withChip" }, ({ generateChipColor }) => (
                      <Chip size="base" {...generateChipColor(paramObj)}>
                        {formattedValue}
                      </Chip>
                    ))
                    .with(
                      { kind: "portfolioAudit" },
                      ({ generateJsonAudit }) => (
                        <AuditTrailCell value={generateJsonAudit(paramObj)} />
                      ),
                    )
                    .with(
                      { kind: "valueWithWarning" },
                      ({ generateMessage }) => {
                        const message = generateMessage(paramObj);

                        return (
                          <Stack direction="row" alignItems="center" gap={0}>
                            <span className="truncate">{formattedValue}</span>

                            {message ? (
                              <TooltipWrapper text={message}>
                                <Warning variant="small" />
                              </TooltipWrapper>
                            ) : undefined}
                          </Stack>
                        );
                      },
                    )
                    .with(
                      { kind: "documentLink" },
                      ({ generateLink, hideOnNodeGroup }) => {
                        const linkType = generateLink(paramObj);

                        const openLink = (link: string) => () => {
                          const suffix =
                            linkType?.kind === "view"
                              ? "#toolbar=0&navpanes=0"
                              : "";

                          linkType?.onClick?.();

                          window.open(
                            link + suffix,
                            "_blank",
                            "noopener noreferrer",
                          );
                        };

                        if (
                          !!hideOnNodeGroup &&
                          rowNode?.type === hideOnNodeGroup
                        )
                          return null;

                        return match(linkType)
                          .with({ kind: "download" }, ({ link }) => (
                            <IconButton
                              Icon={DownloadIcon}
                              onClick={openLink(link)}
                            />
                          ))
                          .with({ kind: "view" }, ({ link }) => (
                            <IconButton
                              Icon={ViewIcon}
                              onClick={openLink(link)}
                            />
                          ))
                          .otherwise(() => tableFallbackValue);
                      },
                    )
                    .with(
                      { kind: "withInlineComponent" },
                      ({ generateCell }) => {
                        const cellDetails = generateCell(paramObj);

                        return (
                          <Stack direction="row" alignItems="center" gap={0}>
                            {cellDetails?.prefixComponent}

                            {cellDetails?.value ? (
                              <Typography
                                variant="tdLabel"
                                fontWeight={
                                  cellDetails?.boldText ? 600 : undefined
                                }
                              >
                                {cellDetails.value}
                              </Typography>
                            ) : undefined}

                            {cellDetails?.postfixComponent}
                          </Stack>
                        );
                      },
                    )
                    .with({ kind: "withTooltip" }, ({ generateTooltip }) => {
                      const tooltipContent = generateTooltip(paramObj);

                      if (!tooltipContent) {
                        return (
                          <span className="truncate">{formattedValue}</span>
                        );
                      }

                      return (
                        <TooltipWrapper text={tooltipContent}>
                          <span className="truncate">{formattedValue}</span>
                        </TooltipWrapper>
                      );
                    })
                    .otherwise(() => (
                      <span className="truncate">{formattedValue}</span>
                    ));
                },
        }),
      ),
    [columns],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage],
  );

  const handleRowsPerPageChange = useCallback(
    (r: number) => {
      setRowsPerPage(r);
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  const slots = useMemo<Partial<GridSlotsComponent>>(
    () => ({
      columnSortedAscendingIcon: () => <ArrowUp variant="small" />,
      columnSortedDescendingIcon: () => <ArrowDown variant="small" />,
      columnHeaderFilterIconButton: () => null,
      noRowsOverlay: () => (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption">
            {customEmptyText || "No data to show"}
          </Typography>
        </Box>
      ),
    }),
    [customEmptyText],
  );

  const paginationModel = useMemo(
    () => ({
      pageSize: rowsPerPage,
      page,
    }),
    [rowsPerPage, page],
  );

  const filterModel = useMemo(
    () =>
      filters
        ? {
            items: filters.map((filter) => ({
              id: filter.column.toString(),
              field: filter.column.toString(),
              operator: "equals",
              value: filter.value,
            })),
          }
        : undefined,
    [filters],
  );

  const groupingColDef = useMemo<GridGroupingColDefOverride<R>>(() => {
    return {
      ...groupingColumn,
      hideDescendantCount: true,
      renderCell: genCustomGridTreeDataGroupingCell(
        groupingColumn?.formatLeafValue,
        defaultGroupsExpanded,
      ),
    };
  }, [groupingColumn, defaultGroupsExpanded]);

  const getTreeDataPath = useCallback((row: R) => row.path ?? [], []);

  const aggregationFunctions = useMemo(
    () => ({
      ...GRID_AGGREGATION_FUNCTIONS,
      [moneySumAggregationLabel]: moneySumAggregation,
      [simpleMoneySumAggregationLabel]: simpleMoneySumAggregation,
      [customFirstValAggregationLabel]: customFirstValAggregation,
      [customSameValAggregationLabel]: customSameValAggregation,
    }),
    [],
  );

  const getAggregationPosition = useCallback(
    (): GridAggregationPosition => (treeData ? "inline" : "footer"),
    [treeData],
  );

  return (
    <ErrorBoundary fallback={<GenericErrorBox />}>
      <DataGridPremium
        apiRef={apiRef}
        columns={computedColumns}
        rows={rows}
        pageSizeOptions={pagesOptions}
        paginationModel={paginationModel}
        filterModel={filterModel}
        rowSelection={false}
        slots={slots}
        treeData={treeData}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        aggregationModel={aggregationModel as Record<string, string>}
        getAggregationPosition={getAggregationPosition}
        aggregationFunctions={aggregationFunctions}
        columnVisibilityModel={visibilityModel as GridColumnVisibilityModel}
        getRowHeight={() => "auto"}
        defaultGroupingExpansionDepth={defaultGroupsExpanded ? -1 : undefined}
        initialState={{
          sorting: defaultSortingModel
            ? {
                sortModel: [
                  {
                    field: defaultSortingModel.field.toString(),
                    sort: defaultSortingModel.sort,
                  },
                ],
              }
            : undefined,
        }}
        pinnedColumns={{
          right: pinnedRightColumns as Array<string>,
        }}
        pinnedRows={{
          bottom: pinnedBottomRows ? pinnedBottomRows : undefined,
        }}
        // FIXME: Here we need to disable the virtualization
        // because on smalled screens when we resize or scroll
        // the table we get a runtime error saying:
        // "Rendered fewer hooks than expected.".
        // Info: https://github.com/mui/mui-x/issues/1402
        disableVirtualization
        disableColumnResize
        disableColumnReorder
        hideFooter
        pagination
        autoHeight
      />

      <Box mt={1}>
        <TablePagination
          count={rows.length}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={pagesOptions}
          rowsPerPage={rowsPerPage}
        />
      </Box>
    </ErrorBoundary>
  );
};
